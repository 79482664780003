body {
	word-wrap: break-word;
}

/* Click button for displaying page template parts */
.regions {
	background: rgba($black, 0.1);
	bottom: 10px;
	color: $white;
	padding: 10px 20px;
	position: fixed;
	right: 10px;
	z-index: 2000000;

	&:hover,
	&:focus {
		color: $white;
	}
}

.hidden {
	display: none;
}

/* Foundation figure was coming out of the layout container */
figure {
	background: $gray244;
	margin: 0;
	max-width: 100% !important;
	padding: 1em 20px;
	width: auto !important;

	img {
		display: block;
		margin: 0 auto;
	}

	figcaption {
		font-size: 12px;
		margin: 13px 0 0;
		text-align: center;
	}
}

/* Wordpress styles */
.alignright {
	float: right;
	margin: 0 0 0 25px;
}

.alignleft {
	float: left;
	margin: 0 25px 0 0;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

pre {
	background-color: $code-background;
	border: $code-border;
	color: $code-color;
	font-family: $code-font-family;
	font-weight: $code-font-weight;
	padding: $code-padding;
	white-space: pre-wrap;
}

[type='text'],
[type='password'] {
	height: 2.48rem;
}

.edit-link {
	margin-bottom: 0;

	.button {
		margin-bottom: 0;
		margin-top: 25px;
	}
}

.screen-reader-text {
	@include element-invisible;
}
