@charset "UTF-8";
/* Custom variables should be brought in early */
/* Colors */
/* Fonts */
/* Import Foundation Assets, does not output actual styles */
/** Foundation for Sites by ZURB Version 6.3.0 foundation.zurb.com Licensed under MIT Open Source */
/** Turn $flex: false to $flex: true for flex grid */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document ========================================================================== */
/** 1. Change the default font family in all browsers (opinionated). 2. Correct the line height in all browsers. 3. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS. */
html { font-family: sans-serif; /* 1 */ line-height: 1.15; /* 2 */ -ms-text-size-adjust: 100%; /* 3 */ -webkit-text-size-adjust: 100%; /* 3 */ }

/* Sections ========================================================================== */
/** Remove the margin in all browsers (opinionated). */
body { margin: 0; }

/** Add the correct display in IE 9-. */
article, aside, footer, header, nav, section { display: block; }

/** Correct the font size and margin on `h1` elements within `section` and `article` contexts in Chrome, Firefox, and Safari. */
h1 { font-size: 2em; margin: 0.67em 0; }

/* Grouping content ========================================================================== */
/** Add the correct display in IE 9-. */
figcaption, figure { display: block; }

/** Add the correct margin in IE 8. */
figure { margin: 1em 40px; }

/** 1. Add the correct box sizing in Firefox. 2. Show the overflow in Edge and IE. */
hr { box-sizing: content-box; /* 1 */ height: 0; /* 1 */ overflow: visible; /* 2 */ }

/** Add the correct display in IE. */
main { display: block; }

/** 1. Correct the inheritance and scaling of font size in all browsers. 2. Correct the odd `em` font sizing in all browsers. */
pre { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

/* Links ========================================================================== */
/** 1. Remove the gray background on active links in IE 10. 2. Remove gaps in links underline in iOS 8+ and Safari 8+. */
a { background-color: transparent; /* 1 */ -webkit-text-decoration-skip: objects; /* 2 */ }

/** Remove the outline on focused links when they are also active or hovered in all browsers (opinionated). */
a:active, a:hover { outline-width: 0; }

/* Text-level semantics ========================================================================== */
/** 1. Remove the bottom border in Firefox 39-. 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari. */
abbr[title] { border-bottom: none; /* 1 */ text-decoration: underline; /* 2 */ text-decoration: underline dotted; /* 2 */ }

/** Prevent the duplicate application of `bolder` by the next rule in Safari 6. */
b, strong { font-weight: inherit; }

/** Add the correct font weight in Chrome, Edge, and Safari. */
b, strong { font-weight: bolder; }

/** 1. Correct the inheritance and scaling of font size in all browsers. 2. Correct the odd `em` font sizing in all browsers. */
code, kbd, samp { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

/** Add the correct font style in Android 4.3-. */
dfn { font-style: italic; }

/** Add the correct background and color in IE 9-. */
mark { background-color: #ff0; color: #000; }

/** Add the correct font size in all browsers. */
small { font-size: 80%; }

/** Prevent `sub` and `sup` elements from affecting the line height in all browsers. */
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }

sub { bottom: -0.25em; }

sup { top: -0.5em; }

/* Embedded content ========================================================================== */
/** Add the correct display in IE 9-. */
audio, video { display: inline-block; }

/** Add the correct display in iOS 4-7. */
audio:not([controls]) { display: none; height: 0; }

/** Remove the border on images inside links in IE 10-. */
img { border-style: none; }

/** Hide the overflow in IE. */
svg:not(:root) { overflow: hidden; }

/* Forms ========================================================================== */
/** 1. Change the font styles in all browsers (opinionated). 2. Remove the margin in Firefox and Safari. */
button, input, optgroup, select, textarea { font-family: sans-serif; /* 1 */ font-size: 100%; /* 1 */ line-height: 1.15; /* 1 */ margin: 0; /* 2 */ }

/** Show the overflow in IE. */
button { overflow: visible; }

/** Remove the inheritance of text transform in Edge, Firefox, and IE. 1. Remove the inheritance of text transform in Firefox. */
button, select { /* 1 */ text-transform: none; }

/** 1. Prevent a WebKit bug where (2) destroys native `audio` and `video` controls in Android 4. 2. Correct the inability to style clickable types in iOS and Safari. */
button, html [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; /* 2 */ }

button, [type="button"], [type="reset"], [type="submit"] { /** Remove the inner border and padding in Firefox. */ /** Restore the focus styles unset by the previous rule. */ }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }

/** Show the overflow in Edge. */
input { overflow: visible; }

/** 1. Add the correct box sizing in IE 10-. 2. Remove the padding in IE 10-. */
[type="checkbox"], [type="radio"] { box-sizing: border-box; /* 1 */ padding: 0; /* 2 */ }

/** Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

/** 1. Correct the odd appearance in Chrome and Safari. 2. Correct the outline style in Safari. */
[type="search"] { -webkit-appearance: textfield; /* 1 */ outline-offset: -2px; /* 2 */ /** Remove the inner padding and cancel buttons in Chrome and Safari on macOS. */ }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

/** 1. Correct the inability to style clickable types in iOS and Safari. 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button { -webkit-appearance: button; /* 1 */ font: inherit; /* 2 */ }

/** Change the border, margin, and padding in all browsers (opinionated). */
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }

/** 1. Correct the text wrapping in Edge and IE. 2. Correct the color inheritance from `fieldset` elements in IE. 3. Remove the padding so developers are not caught out when they zero out `fieldset` elements in all browsers. */
legend { box-sizing: border-box; /* 1 */ display: table; /* 1 */ max-width: 100%; /* 1 */ padding: 0; /* 3 */ color: inherit; /* 2 */ white-space: normal; /* 1 */ }

/** 1. Add the correct display in IE 9-. 2. Add the correct vertical alignment in Chrome, Firefox, and Opera. */
progress { display: inline-block; /* 1 */ vertical-align: baseline; /* 2 */ }

/** Remove the default vertical scrollbar in IE. */
textarea { overflow: auto; }

/* Interactive ========================================================================== */
/* Add the correct display in Edge, IE, and Firefox. */
details { display: block; }

/* Add the correct display in all browsers. */
summary { display: list-item; }

/* Add the correct display in IE 9-. */
menu { display: block; }

/* Scripting ========================================================================== */
/** Add the correct display in IE 9-. */
canvas { display: inline-block; }

/** Add the correct display in IE. */
template { display: none; }

/* Hidden ========================================================================== */
/** Add the correct display in IE 10-. */
[hidden] { display: none; }

.foundation-mq { font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html { box-sizing: border-box; font-size: 100%; }

*, *::before, *::after { box-sizing: inherit; }

body { margin: 0; padding: 0; background: #fefefe; font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; font-weight: normal; line-height: 1.5; color: #0a0a0a; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

img { display: inline-block; vertical-align: middle; max-width: 100%; height: auto; -ms-interpolation-mode: bicubic; }

textarea { height: auto; min-height: 50px; border-radius: 0; }

select { box-sizing: border-box; width: 100%; border-radius: 0; }

.map_canvas img, .map_canvas embed, .map_canvas object, .mqa-display img, .mqa-display embed, .mqa-display object { max-width: none !important; }

button { padding: 0; appearance: none; border: 0; border-radius: 0; background: transparent; line-height: 1; cursor: auto; }

[data-whatinput='mouse'] button { outline: 0; }

pre { overflow: auto; }

button, input, optgroup, select, textarea { font-family: inherit; }

.is-visible { display: block !important; }

.is-hidden { display: none !important; }

.grid-container { padding-right: 0.625rem; padding-left: 0.625rem; max-width: 75rem; margin: 0 auto; }

@media print, screen and (min-width: 40em) { .grid-container { padding-right: 0.9375rem; padding-left: 0.9375rem; } }

.grid-container.fluid { padding-right: 0.625rem; padding-left: 0.625rem; max-width: 100%; margin: 0 auto; }

@media print, screen and (min-width: 40em) { .grid-container.fluid { padding-right: 0.9375rem; padding-left: 0.9375rem; } }

.grid-container.full { padding-right: 0; padding-left: 0; max-width: 100%; margin: 0 auto; }

.grid-x { display: flex; flex-flow: row wrap; }

.cell { flex: 0 0 auto; min-height: 0px; min-width: 0px; width: 100%; }

.cell.auto { flex: 1 1 0px; }

.cell.shrink { flex: 0 0 auto; }

.grid-x > .auto { width: auto; }

.grid-x > .shrink { width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 { flex-basis: auto; }

@media print, screen and (min-width: 40em) { .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 { flex-basis: auto; } }

@media print, screen and (min-width: 64em) { .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 { flex-basis: auto; } }

.grid-x > .small-1 { width: 8.3333333333%; }

.grid-x > .small-2 { width: 16.6666666667%; }

.grid-x > .small-3 { width: 25%; }

.grid-x > .small-4 { width: 33.3333333333%; }

.grid-x > .small-5 { width: 41.6666666667%; }

.grid-x > .small-6 { width: 50%; }

.grid-x > .small-7 { width: 58.3333333333%; }

.grid-x > .small-8 { width: 66.6666666667%; }

.grid-x > .small-9 { width: 75%; }

.grid-x > .small-10 { width: 83.3333333333%; }

.grid-x > .small-11 { width: 91.6666666667%; }

.grid-x > .small-12 { width: 100%; }

@media print, screen and (min-width: 40em) { .grid-x > .medium-auto { flex: 1 1 0px; width: auto; }
  .grid-x > .medium-shrink { flex: 0 0 auto; width: auto; }
  .grid-x > .medium-1 { width: 8.3333333333%; }
  .grid-x > .medium-2 { width: 16.6666666667%; }
  .grid-x > .medium-3 { width: 25%; }
  .grid-x > .medium-4 { width: 33.3333333333%; }
  .grid-x > .medium-5 { width: 41.6666666667%; }
  .grid-x > .medium-6 { width: 50%; }
  .grid-x > .medium-7 { width: 58.3333333333%; }
  .grid-x > .medium-8 { width: 66.6666666667%; }
  .grid-x > .medium-9 { width: 75%; }
  .grid-x > .medium-10 { width: 83.3333333333%; }
  .grid-x > .medium-11 { width: 91.6666666667%; }
  .grid-x > .medium-12 { width: 100%; } }

@media print, screen and (min-width: 64em) { .grid-x > .large-auto { flex: 1 1 0px; width: auto; }
  .grid-x > .large-shrink { flex: 0 0 auto; width: auto; }
  .grid-x > .large-1 { width: 8.3333333333%; }
  .grid-x > .large-2 { width: 16.6666666667%; }
  .grid-x > .large-3 { width: 25%; }
  .grid-x > .large-4 { width: 33.3333333333%; }
  .grid-x > .large-5 { width: 41.6666666667%; }
  .grid-x > .large-6 { width: 50%; }
  .grid-x > .large-7 { width: 58.3333333333%; }
  .grid-x > .large-8 { width: 66.6666666667%; }
  .grid-x > .large-9 { width: 75%; }
  .grid-x > .large-10 { width: 83.3333333333%; }
  .grid-x > .large-11 { width: 91.6666666667%; }
  .grid-x > .large-12 { width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell { width: auto; }

.grid-margin-y:not(.grid-y) > .cell { height: auto; }

.grid-margin-x { margin-left: -0.625rem; margin-right: -0.625rem; }

@media print, screen and (min-width: 40em) { .grid-margin-x { margin-left: -0.9375rem; margin-right: -0.9375rem; } }

.grid-margin-x > .cell { width: calc(100% - 1.25rem); margin-left: 0.625rem; margin-right: 0.625rem; }

@media print, screen and (min-width: 40em) { .grid-margin-x > .cell { width: calc(100% - 1.875rem); margin-left: 0.9375rem; margin-right: 0.9375rem; } }

.grid-margin-x > .auto { width: auto; }

.grid-margin-x > .shrink { width: auto; }

.grid-margin-x > .small-1 { width: calc(8.3333333333% - 1.25rem); }

.grid-margin-x > .small-2 { width: calc(16.6666666667% - 1.25rem); }

.grid-margin-x > .small-3 { width: calc(25% - 1.25rem); }

.grid-margin-x > .small-4 { width: calc(33.3333333333% - 1.25rem); }

.grid-margin-x > .small-5 { width: calc(41.6666666667% - 1.25rem); }

.grid-margin-x > .small-6 { width: calc(50% - 1.25rem); }

.grid-margin-x > .small-7 { width: calc(58.3333333333% - 1.25rem); }

.grid-margin-x > .small-8 { width: calc(66.6666666667% - 1.25rem); }

.grid-margin-x > .small-9 { width: calc(75% - 1.25rem); }

.grid-margin-x > .small-10 { width: calc(83.3333333333% - 1.25rem); }

.grid-margin-x > .small-11 { width: calc(91.6666666667% - 1.25rem); }

.grid-margin-x > .small-12 { width: calc(100% - 1.25rem); }

@media print, screen and (min-width: 40em) { .grid-margin-x > .auto { width: auto; }
  .grid-margin-x > .shrink { width: auto; }
  .grid-margin-x > .small-1 { width: calc(8.3333333333% - 1.875rem); }
  .grid-margin-x > .small-2 { width: calc(16.6666666667% - 1.875rem); }
  .grid-margin-x > .small-3 { width: calc(25% - 1.875rem); }
  .grid-margin-x > .small-4 { width: calc(33.3333333333% - 1.875rem); }
  .grid-margin-x > .small-5 { width: calc(41.6666666667% - 1.875rem); }
  .grid-margin-x > .small-6 { width: calc(50% - 1.875rem); }
  .grid-margin-x > .small-7 { width: calc(58.3333333333% - 1.875rem); }
  .grid-margin-x > .small-8 { width: calc(66.6666666667% - 1.875rem); }
  .grid-margin-x > .small-9 { width: calc(75% - 1.875rem); }
  .grid-margin-x > .small-10 { width: calc(83.3333333333% - 1.875rem); }
  .grid-margin-x > .small-11 { width: calc(91.6666666667% - 1.875rem); }
  .grid-margin-x > .small-12 { width: calc(100% - 1.875rem); }
  .grid-margin-x > .medium-auto { width: auto; }
  .grid-margin-x > .medium-shrink { width: auto; }
  .grid-margin-x > .medium-1 { width: calc(8.3333333333% - 1.875rem); }
  .grid-margin-x > .medium-2 { width: calc(16.6666666667% - 1.875rem); }
  .grid-margin-x > .medium-3 { width: calc(25% - 1.875rem); }
  .grid-margin-x > .medium-4 { width: calc(33.3333333333% - 1.875rem); }
  .grid-margin-x > .medium-5 { width: calc(41.6666666667% - 1.875rem); }
  .grid-margin-x > .medium-6 { width: calc(50% - 1.875rem); }
  .grid-margin-x > .medium-7 { width: calc(58.3333333333% - 1.875rem); }
  .grid-margin-x > .medium-8 { width: calc(66.6666666667% - 1.875rem); }
  .grid-margin-x > .medium-9 { width: calc(75% - 1.875rem); }
  .grid-margin-x > .medium-10 { width: calc(83.3333333333% - 1.875rem); }
  .grid-margin-x > .medium-11 { width: calc(91.6666666667% - 1.875rem); }
  .grid-margin-x > .medium-12 { width: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 64em) { .grid-margin-x > .large-auto { width: auto; }
  .grid-margin-x > .large-shrink { width: auto; }
  .grid-margin-x > .large-1 { width: calc(8.3333333333% - 1.875rem); }
  .grid-margin-x > .large-2 { width: calc(16.6666666667% - 1.875rem); }
  .grid-margin-x > .large-3 { width: calc(25% - 1.875rem); }
  .grid-margin-x > .large-4 { width: calc(33.3333333333% - 1.875rem); }
  .grid-margin-x > .large-5 { width: calc(41.6666666667% - 1.875rem); }
  .grid-margin-x > .large-6 { width: calc(50% - 1.875rem); }
  .grid-margin-x > .large-7 { width: calc(58.3333333333% - 1.875rem); }
  .grid-margin-x > .large-8 { width: calc(66.6666666667% - 1.875rem); }
  .grid-margin-x > .large-9 { width: calc(75% - 1.875rem); }
  .grid-margin-x > .large-10 { width: calc(83.3333333333% - 1.875rem); }
  .grid-margin-x > .large-11 { width: calc(91.6666666667% - 1.875rem); }
  .grid-margin-x > .large-12 { width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x { margin-right: -0.625rem; margin-left: -0.625rem; }

@media print, screen and (min-width: 40em) { .grid-padding-x .grid-padding-x { margin-right: -0.9375rem; margin-left: -0.9375rem; } }

.grid-container:not(.full) > .grid-padding-x { margin-right: -0.625rem; margin-left: -0.625rem; }

@media print, screen and (min-width: 40em) { .grid-container:not(.full) > .grid-padding-x { margin-right: -0.9375rem; margin-left: -0.9375rem; } }

.grid-padding-x > .cell { padding-right: 0.625rem; padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) { .grid-padding-x > .cell { padding-right: 0.9375rem; padding-left: 0.9375rem; } }

.small-up-1 > .cell { width: 100%; }

.small-up-2 > .cell { width: 50%; }

.small-up-3 > .cell { width: 33.3333333333%; }

.small-up-4 > .cell { width: 25%; }

.small-up-5 > .cell { width: 20%; }

.small-up-6 > .cell { width: 16.6666666667%; }

.small-up-7 > .cell { width: 14.2857142857%; }

.small-up-8 > .cell { width: 12.5%; }

@media print, screen and (min-width: 40em) { .medium-up-1 > .cell { width: 100%; }
  .medium-up-2 > .cell { width: 50%; }
  .medium-up-3 > .cell { width: 33.3333333333%; }
  .medium-up-4 > .cell { width: 25%; }
  .medium-up-5 > .cell { width: 20%; }
  .medium-up-6 > .cell { width: 16.6666666667%; }
  .medium-up-7 > .cell { width: 14.2857142857%; }
  .medium-up-8 > .cell { width: 12.5%; } }

@media print, screen and (min-width: 64em) { .large-up-1 > .cell { width: 100%; }
  .large-up-2 > .cell { width: 50%; }
  .large-up-3 > .cell { width: 33.3333333333%; }
  .large-up-4 > .cell { width: 25%; }
  .large-up-5 > .cell { width: 20%; }
  .large-up-6 > .cell { width: 16.6666666667%; }
  .large-up-7 > .cell { width: 14.2857142857%; }
  .large-up-8 > .cell { width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell { width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell { width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell { width: calc(33.3333333333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell { width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell { width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell { width: calc(16.6666666667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell { width: calc(14.2857142857% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell { width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) { .grid-margin-x.small-up-1 > .cell { width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell { width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell { width: calc(33.3333333333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell { width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell { width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell { width: calc(16.6666666667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell { width: calc(14.2857142857% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell { width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-up-1 > .cell { width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell { width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell { width: calc(33.3333333333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell { width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell { width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell { width: calc(16.6666666667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell { width: calc(14.2857142857% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell { width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) { .grid-margin-x.large-up-1 > .cell { width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell { width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell { width: calc(33.3333333333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell { width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell { width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell { width: calc(16.6666666667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell { width: calc(14.2857142857% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell { width: calc(12.5% - 1.875rem); } }

.small-margin-collapse { margin-right: 0; margin-left: 0; }

.small-margin-collapse > .cell { margin-right: 0; margin-left: 0; }

.small-margin-collapse > .small-1 { width: 8.3333333333%; }

.small-margin-collapse > .small-2 { width: 16.6666666667%; }

.small-margin-collapse > .small-3 { width: 25%; }

.small-margin-collapse > .small-4 { width: 33.3333333333%; }

.small-margin-collapse > .small-5 { width: 41.6666666667%; }

.small-margin-collapse > .small-6 { width: 50%; }

.small-margin-collapse > .small-7 { width: 58.3333333333%; }

.small-margin-collapse > .small-8 { width: 66.6666666667%; }

.small-margin-collapse > .small-9 { width: 75%; }

.small-margin-collapse > .small-10 { width: 83.3333333333%; }

.small-margin-collapse > .small-11 { width: 91.6666666667%; }

.small-margin-collapse > .small-12 { width: 100%; }

.small-margin-collapse > .medium-1 { width: 8.3333333333%; }

.small-margin-collapse > .medium-2 { width: 16.6666666667%; }

.small-margin-collapse > .medium-3 { width: 25%; }

.small-margin-collapse > .medium-4 { width: 33.3333333333%; }

.small-margin-collapse > .medium-5 { width: 41.6666666667%; }

.small-margin-collapse > .medium-6 { width: 50%; }

.small-margin-collapse > .medium-7 { width: 58.3333333333%; }

.small-margin-collapse > .medium-8 { width: 66.6666666667%; }

.small-margin-collapse > .medium-9 { width: 75%; }

.small-margin-collapse > .medium-10 { width: 83.3333333333%; }

.small-margin-collapse > .medium-11 { width: 91.6666666667%; }

.small-margin-collapse > .medium-12 { width: 100%; }

.small-margin-collapse > .large-1 { width: 8.3333333333%; }

.small-margin-collapse > .large-2 { width: 16.6666666667%; }

.small-margin-collapse > .large-3 { width: 25%; }

.small-margin-collapse > .large-4 { width: 33.3333333333%; }

.small-margin-collapse > .large-5 { width: 41.6666666667%; }

.small-margin-collapse > .large-6 { width: 50%; }

.small-margin-collapse > .large-7 { width: 58.3333333333%; }

.small-margin-collapse > .large-8 { width: 66.6666666667%; }

.small-margin-collapse > .large-9 { width: 75%; }

.small-margin-collapse > .large-10 { width: 83.3333333333%; }

.small-margin-collapse > .large-11 { width: 91.6666666667%; }

.small-margin-collapse > .large-12 { width: 100%; }

.small-padding-collapse { margin-right: 0; margin-left: 0; }

.small-padding-collapse > .cell { padding-right: 0; padding-left: 0; }

@media print, screen and (min-width: 40em) { .medium-margin-collapse { margin-right: 0; margin-left: 0; }
  .medium-margin-collapse > .cell { margin-right: 0; margin-left: 0; } }

@media print, screen and (min-width: 40em) { .medium-margin-collapse > .small-1 { width: 8.3333333333%; }
  .medium-margin-collapse > .small-2 { width: 16.6666666667%; }
  .medium-margin-collapse > .small-3 { width: 25%; }
  .medium-margin-collapse > .small-4 { width: 33.3333333333%; }
  .medium-margin-collapse > .small-5 { width: 41.6666666667%; }
  .medium-margin-collapse > .small-6 { width: 50%; }
  .medium-margin-collapse > .small-7 { width: 58.3333333333%; }
  .medium-margin-collapse > .small-8 { width: 66.6666666667%; }
  .medium-margin-collapse > .small-9 { width: 75%; }
  .medium-margin-collapse > .small-10 { width: 83.3333333333%; }
  .medium-margin-collapse > .small-11 { width: 91.6666666667%; }
  .medium-margin-collapse > .small-12 { width: 100%; } }

@media print, screen and (min-width: 40em) { .medium-margin-collapse > .medium-1 { width: 8.3333333333%; }
  .medium-margin-collapse > .medium-2 { width: 16.6666666667%; }
  .medium-margin-collapse > .medium-3 { width: 25%; }
  .medium-margin-collapse > .medium-4 { width: 33.3333333333%; }
  .medium-margin-collapse > .medium-5 { width: 41.6666666667%; }
  .medium-margin-collapse > .medium-6 { width: 50%; }
  .medium-margin-collapse > .medium-7 { width: 58.3333333333%; }
  .medium-margin-collapse > .medium-8 { width: 66.6666666667%; }
  .medium-margin-collapse > .medium-9 { width: 75%; }
  .medium-margin-collapse > .medium-10 { width: 83.3333333333%; }
  .medium-margin-collapse > .medium-11 { width: 91.6666666667%; }
  .medium-margin-collapse > .medium-12 { width: 100%; } }

@media print, screen and (min-width: 40em) { .medium-margin-collapse > .large-1 { width: 8.3333333333%; }
  .medium-margin-collapse > .large-2 { width: 16.6666666667%; }
  .medium-margin-collapse > .large-3 { width: 25%; }
  .medium-margin-collapse > .large-4 { width: 33.3333333333%; }
  .medium-margin-collapse > .large-5 { width: 41.6666666667%; }
  .medium-margin-collapse > .large-6 { width: 50%; }
  .medium-margin-collapse > .large-7 { width: 58.3333333333%; }
  .medium-margin-collapse > .large-8 { width: 66.6666666667%; }
  .medium-margin-collapse > .large-9 { width: 75%; }
  .medium-margin-collapse > .large-10 { width: 83.3333333333%; }
  .medium-margin-collapse > .large-11 { width: 91.6666666667%; }
  .medium-margin-collapse > .large-12 { width: 100%; } }

@media print, screen and (min-width: 40em) { .medium-padding-collapse { margin-right: 0; margin-left: 0; }
  .medium-padding-collapse > .cell { padding-right: 0; padding-left: 0; } }

@media print, screen and (min-width: 64em) { .large-margin-collapse { margin-right: 0; margin-left: 0; }
  .large-margin-collapse > .cell { margin-right: 0; margin-left: 0; } }

@media print, screen and (min-width: 64em) { .large-margin-collapse > .small-1 { width: 8.3333333333%; }
  .large-margin-collapse > .small-2 { width: 16.6666666667%; }
  .large-margin-collapse > .small-3 { width: 25%; }
  .large-margin-collapse > .small-4 { width: 33.3333333333%; }
  .large-margin-collapse > .small-5 { width: 41.6666666667%; }
  .large-margin-collapse > .small-6 { width: 50%; }
  .large-margin-collapse > .small-7 { width: 58.3333333333%; }
  .large-margin-collapse > .small-8 { width: 66.6666666667%; }
  .large-margin-collapse > .small-9 { width: 75%; }
  .large-margin-collapse > .small-10 { width: 83.3333333333%; }
  .large-margin-collapse > .small-11 { width: 91.6666666667%; }
  .large-margin-collapse > .small-12 { width: 100%; } }

@media print, screen and (min-width: 64em) { .large-margin-collapse > .medium-1 { width: 8.3333333333%; }
  .large-margin-collapse > .medium-2 { width: 16.6666666667%; }
  .large-margin-collapse > .medium-3 { width: 25%; }
  .large-margin-collapse > .medium-4 { width: 33.3333333333%; }
  .large-margin-collapse > .medium-5 { width: 41.6666666667%; }
  .large-margin-collapse > .medium-6 { width: 50%; }
  .large-margin-collapse > .medium-7 { width: 58.3333333333%; }
  .large-margin-collapse > .medium-8 { width: 66.6666666667%; }
  .large-margin-collapse > .medium-9 { width: 75%; }
  .large-margin-collapse > .medium-10 { width: 83.3333333333%; }
  .large-margin-collapse > .medium-11 { width: 91.6666666667%; }
  .large-margin-collapse > .medium-12 { width: 100%; } }

@media print, screen and (min-width: 64em) { .large-margin-collapse > .large-1 { width: 8.3333333333%; }
  .large-margin-collapse > .large-2 { width: 16.6666666667%; }
  .large-margin-collapse > .large-3 { width: 25%; }
  .large-margin-collapse > .large-4 { width: 33.3333333333%; }
  .large-margin-collapse > .large-5 { width: 41.6666666667%; }
  .large-margin-collapse > .large-6 { width: 50%; }
  .large-margin-collapse > .large-7 { width: 58.3333333333%; }
  .large-margin-collapse > .large-8 { width: 66.6666666667%; }
  .large-margin-collapse > .large-9 { width: 75%; }
  .large-margin-collapse > .large-10 { width: 83.3333333333%; }
  .large-margin-collapse > .large-11 { width: 91.6666666667%; }
  .large-margin-collapse > .large-12 { width: 100%; } }

@media print, screen and (min-width: 64em) { .large-padding-collapse { margin-right: 0; margin-left: 0; }
  .large-padding-collapse > .cell { padding-right: 0; padding-left: 0; } }

.small-offset-0 { margin-left: 0%; }

.grid-margin-x > .small-offset-0 { margin-left: calc(0% + 0.625rem); }

.small-offset-1 { margin-left: 8.3333333333%; }

.grid-margin-x > .small-offset-1 { margin-left: calc(8.3333333333% + 0.625rem); }

.small-offset-2 { margin-left: 16.6666666667%; }

.grid-margin-x > .small-offset-2 { margin-left: calc(16.6666666667% + 0.625rem); }

.small-offset-3 { margin-left: 25%; }

.grid-margin-x > .small-offset-3 { margin-left: calc(25% + 0.625rem); }

.small-offset-4 { margin-left: 33.3333333333%; }

.grid-margin-x > .small-offset-4 { margin-left: calc(33.3333333333% + 0.625rem); }

.small-offset-5 { margin-left: 41.6666666667%; }

.grid-margin-x > .small-offset-5 { margin-left: calc(41.6666666667% + 0.625rem); }

.small-offset-6 { margin-left: 50%; }

.grid-margin-x > .small-offset-6 { margin-left: calc(50% + 0.625rem); }

.small-offset-7 { margin-left: 58.3333333333%; }

.grid-margin-x > .small-offset-7 { margin-left: calc(58.3333333333% + 0.625rem); }

.small-offset-8 { margin-left: 66.6666666667%; }

.grid-margin-x > .small-offset-8 { margin-left: calc(66.6666666667% + 0.625rem); }

.small-offset-9 { margin-left: 75%; }

.grid-margin-x > .small-offset-9 { margin-left: calc(75% + 0.625rem); }

.small-offset-10 { margin-left: 83.3333333333%; }

.grid-margin-x > .small-offset-10 { margin-left: calc(83.3333333333% + 0.625rem); }

.small-offset-11 { margin-left: 91.6666666667%; }

.grid-margin-x > .small-offset-11 { margin-left: calc(91.6666666667% + 0.625rem); }

@media print, screen and (min-width: 40em) { .medium-offset-0 { margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 { margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 { margin-left: 8.3333333333%; }
  .grid-margin-x > .medium-offset-1 { margin-left: calc(8.3333333333% + 0.9375rem); }
  .medium-offset-2 { margin-left: 16.6666666667%; }
  .grid-margin-x > .medium-offset-2 { margin-left: calc(16.6666666667% + 0.9375rem); }
  .medium-offset-3 { margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 { margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 { margin-left: 33.3333333333%; }
  .grid-margin-x > .medium-offset-4 { margin-left: calc(33.3333333333% + 0.9375rem); }
  .medium-offset-5 { margin-left: 41.6666666667%; }
  .grid-margin-x > .medium-offset-5 { margin-left: calc(41.6666666667% + 0.9375rem); }
  .medium-offset-6 { margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 { margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 { margin-left: 58.3333333333%; }
  .grid-margin-x > .medium-offset-7 { margin-left: calc(58.3333333333% + 0.9375rem); }
  .medium-offset-8 { margin-left: 66.6666666667%; }
  .grid-margin-x > .medium-offset-8 { margin-left: calc(66.6666666667% + 0.9375rem); }
  .medium-offset-9 { margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 { margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 { margin-left: 83.3333333333%; }
  .grid-margin-x > .medium-offset-10 { margin-left: calc(83.3333333333% + 0.9375rem); }
  .medium-offset-11 { margin-left: 91.6666666667%; }
  .grid-margin-x > .medium-offset-11 { margin-left: calc(91.6666666667% + 0.9375rem); } }

@media print, screen and (min-width: 64em) { .large-offset-0 { margin-left: 0%; }
  .grid-margin-x > .large-offset-0 { margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 { margin-left: 8.3333333333%; }
  .grid-margin-x > .large-offset-1 { margin-left: calc(8.3333333333% + 0.9375rem); }
  .large-offset-2 { margin-left: 16.6666666667%; }
  .grid-margin-x > .large-offset-2 { margin-left: calc(16.6666666667% + 0.9375rem); }
  .large-offset-3 { margin-left: 25%; }
  .grid-margin-x > .large-offset-3 { margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 { margin-left: 33.3333333333%; }
  .grid-margin-x > .large-offset-4 { margin-left: calc(33.3333333333% + 0.9375rem); }
  .large-offset-5 { margin-left: 41.6666666667%; }
  .grid-margin-x > .large-offset-5 { margin-left: calc(41.6666666667% + 0.9375rem); }
  .large-offset-6 { margin-left: 50%; }
  .grid-margin-x > .large-offset-6 { margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 { margin-left: 58.3333333333%; }
  .grid-margin-x > .large-offset-7 { margin-left: calc(58.3333333333% + 0.9375rem); }
  .large-offset-8 { margin-left: 66.6666666667%; }
  .grid-margin-x > .large-offset-8 { margin-left: calc(66.6666666667% + 0.9375rem); }
  .large-offset-9 { margin-left: 75%; }
  .grid-margin-x > .large-offset-9 { margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 { margin-left: 83.3333333333%; }
  .grid-margin-x > .large-offset-10 { margin-left: calc(83.3333333333% + 0.9375rem); }
  .large-offset-11 { margin-left: 91.6666666667%; }
  .grid-margin-x > .large-offset-11 { margin-left: calc(91.6666666667% + 0.9375rem); } }

.grid-y { display: flex; flex-flow: column nowrap; }

.grid-y > .cell { width: auto; }

.grid-y > .auto { height: auto; }

.grid-y > .shrink { height: auto; }

.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 { flex-basis: auto; }

@media print, screen and (min-width: 40em) { .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 { flex-basis: auto; } }

@media print, screen and (min-width: 64em) { .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 { flex-basis: auto; } }

.grid-y > .small-1 { height: 8.3333333333%; }

.grid-y > .small-2 { height: 16.6666666667%; }

.grid-y > .small-3 { height: 25%; }

.grid-y > .small-4 { height: 33.3333333333%; }

.grid-y > .small-5 { height: 41.6666666667%; }

.grid-y > .small-6 { height: 50%; }

.grid-y > .small-7 { height: 58.3333333333%; }

.grid-y > .small-8 { height: 66.6666666667%; }

.grid-y > .small-9 { height: 75%; }

.grid-y > .small-10 { height: 83.3333333333%; }

.grid-y > .small-11 { height: 91.6666666667%; }

.grid-y > .small-12 { height: 100%; }

@media print, screen and (min-width: 40em) { .grid-y > .medium-auto { flex: 1 1 0px; height: auto; }
  .grid-y > .medium-shrink { height: auto; }
  .grid-y > .medium-1 { height: 8.3333333333%; }
  .grid-y > .medium-2 { height: 16.6666666667%; }
  .grid-y > .medium-3 { height: 25%; }
  .grid-y > .medium-4 { height: 33.3333333333%; }
  .grid-y > .medium-5 { height: 41.6666666667%; }
  .grid-y > .medium-6 { height: 50%; }
  .grid-y > .medium-7 { height: 58.3333333333%; }
  .grid-y > .medium-8 { height: 66.6666666667%; }
  .grid-y > .medium-9 { height: 75%; }
  .grid-y > .medium-10 { height: 83.3333333333%; }
  .grid-y > .medium-11 { height: 91.6666666667%; }
  .grid-y > .medium-12 { height: 100%; } }

@media print, screen and (min-width: 64em) { .grid-y > .large-auto { flex: 1 1 0px; height: auto; }
  .grid-y > .large-shrink { height: auto; }
  .grid-y > .large-1 { height: 8.3333333333%; }
  .grid-y > .large-2 { height: 16.6666666667%; }
  .grid-y > .large-3 { height: 25%; }
  .grid-y > .large-4 { height: 33.3333333333%; }
  .grid-y > .large-5 { height: 41.6666666667%; }
  .grid-y > .large-6 { height: 50%; }
  .grid-y > .large-7 { height: 58.3333333333%; }
  .grid-y > .large-8 { height: 66.6666666667%; }
  .grid-y > .large-9 { height: 75%; }
  .grid-y > .large-10 { height: 83.3333333333%; }
  .grid-y > .large-11 { height: 91.6666666667%; }
  .grid-y > .large-12 { height: 100%; } }

.grid-padding-y .grid-padding-y { margin-top: -0.625rem; margin-bottom: -0.625rem; }

@media print, screen and (min-width: 40em) { .grid-padding-y .grid-padding-y { margin-top: -0.9375rem; margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell { padding-top: 0.625rem; padding-bottom: 0.625rem; }

@media print, screen and (min-width: 40em) { .grid-padding-y > .cell { padding-top: 0.9375rem; padding-bottom: 0.9375rem; } }

.grid-margin-y { margin-top: -0.625rem; margin-bottom: -0.625rem; }

@media print, screen and (min-width: 40em) { .grid-margin-y { margin-top: -0.9375rem; margin-bottom: -0.9375rem; } }

.grid-margin-y > .cell { height: calc(100% - 1.25rem); margin-top: 0.625rem; margin-bottom: 0.625rem; }

@media print, screen and (min-width: 40em) { .grid-margin-y > .cell { height: calc(100% - 1.875rem); margin-top: 0.9375rem; margin-bottom: 0.9375rem; } }

.grid-margin-y > .auto { height: auto; }

.grid-margin-y > .shrink { height: auto; }

.grid-margin-y > .small-1 { height: calc(8.3333333333% - 1.25rem); }

.grid-margin-y > .small-2 { height: calc(16.6666666667% - 1.25rem); }

.grid-margin-y > .small-3 { height: calc(25% - 1.25rem); }

.grid-margin-y > .small-4 { height: calc(33.3333333333% - 1.25rem); }

.grid-margin-y > .small-5 { height: calc(41.6666666667% - 1.25rem); }

.grid-margin-y > .small-6 { height: calc(50% - 1.25rem); }

.grid-margin-y > .small-7 { height: calc(58.3333333333% - 1.25rem); }

.grid-margin-y > .small-8 { height: calc(66.6666666667% - 1.25rem); }

.grid-margin-y > .small-9 { height: calc(75% - 1.25rem); }

.grid-margin-y > .small-10 { height: calc(83.3333333333% - 1.25rem); }

.grid-margin-y > .small-11 { height: calc(91.6666666667% - 1.25rem); }

.grid-margin-y > .small-12 { height: calc(100% - 1.25rem); }

@media print, screen and (min-width: 40em) { .grid-margin-y > .auto { height: auto; }
  .grid-margin-y > .shrink { height: auto; }
  .grid-margin-y > .small-1 { height: calc(8.3333333333% - 1.875rem); }
  .grid-margin-y > .small-2 { height: calc(16.6666666667% - 1.875rem); }
  .grid-margin-y > .small-3 { height: calc(25% - 1.875rem); }
  .grid-margin-y > .small-4 { height: calc(33.3333333333% - 1.875rem); }
  .grid-margin-y > .small-5 { height: calc(41.6666666667% - 1.875rem); }
  .grid-margin-y > .small-6 { height: calc(50% - 1.875rem); }
  .grid-margin-y > .small-7 { height: calc(58.3333333333% - 1.875rem); }
  .grid-margin-y > .small-8 { height: calc(66.6666666667% - 1.875rem); }
  .grid-margin-y > .small-9 { height: calc(75% - 1.875rem); }
  .grid-margin-y > .small-10 { height: calc(83.3333333333% - 1.875rem); }
  .grid-margin-y > .small-11 { height: calc(91.6666666667% - 1.875rem); }
  .grid-margin-y > .small-12 { height: calc(100% - 1.875rem); }
  .grid-margin-y > .medium-auto { height: auto; }
  .grid-margin-y > .medium-shrink { height: auto; }
  .grid-margin-y > .medium-1 { height: calc(8.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-2 { height: calc(16.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-3 { height: calc(25% - 1.875rem); }
  .grid-margin-y > .medium-4 { height: calc(33.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-5 { height: calc(41.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-6 { height: calc(50% - 1.875rem); }
  .grid-margin-y > .medium-7 { height: calc(58.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-8 { height: calc(66.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-9 { height: calc(75% - 1.875rem); }
  .grid-margin-y > .medium-10 { height: calc(83.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-11 { height: calc(91.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-12 { height: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 64em) { .grid-margin-y > .large-auto { height: auto; }
  .grid-margin-y > .large-shrink { height: auto; }
  .grid-margin-y > .large-1 { height: calc(8.3333333333% - 1.875rem); }
  .grid-margin-y > .large-2 { height: calc(16.6666666667% - 1.875rem); }
  .grid-margin-y > .large-3 { height: calc(25% - 1.875rem); }
  .grid-margin-y > .large-4 { height: calc(33.3333333333% - 1.875rem); }
  .grid-margin-y > .large-5 { height: calc(41.6666666667% - 1.875rem); }
  .grid-margin-y > .large-6 { height: calc(50% - 1.875rem); }
  .grid-margin-y > .large-7 { height: calc(58.3333333333% - 1.875rem); }
  .grid-margin-y > .large-8 { height: calc(66.6666666667% - 1.875rem); }
  .grid-margin-y > .large-9 { height: calc(75% - 1.875rem); }
  .grid-margin-y > .large-10 { height: calc(83.3333333333% - 1.875rem); }
  .grid-margin-y > .large-11 { height: calc(91.6666666667% - 1.875rem); }
  .grid-margin-y > .large-12 { height: calc(100% - 1.875rem); } }

.grid-frame { overflow: hidden; position: relative; flex-wrap: nowrap; align-items: stretch; width: 100vw; }

.cell .grid-frame { width: 100%; }

.cell-block { overflow-x: auto; max-width: 100%; -webkit-overflow-scrolling: touch; -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y { overflow-y: auto; max-height: 100%; -webkit-overflow-scrolling: touch; -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container { display: flex; flex-direction: column; max-height: 100%; }

.cell-block-container > .grid-x { max-height: 100%; flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) { .medium-grid-frame { overflow: hidden; position: relative; flex-wrap: nowrap; align-items: stretch; width: 100vw; }
  .cell .medium-grid-frame { width: 100%; }
  .medium-cell-block { overflow-x: auto; max-width: 100%; -webkit-overflow-scrolling: touch; -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container { display: flex; flex-direction: column; max-height: 100%; }
  .medium-cell-block-container > .grid-x { max-height: 100%; flex-wrap: nowrap; }
  .medium-cell-block-y { overflow-y: auto; max-height: 100%; -webkit-overflow-scrolling: touch; -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) { .large-grid-frame { overflow: hidden; position: relative; flex-wrap: nowrap; align-items: stretch; width: 100vw; }
  .cell .large-grid-frame { width: 100%; }
  .large-cell-block { overflow-x: auto; max-width: 100%; -webkit-overflow-scrolling: touch; -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container { display: flex; flex-direction: column; max-height: 100%; }
  .large-cell-block-container > .grid-x { max-height: 100%; flex-wrap: nowrap; }
  .large-cell-block-y { overflow-y: auto; max-height: 100%; -webkit-overflow-scrolling: touch; -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame { width: auto; overflow: hidden; position: relative; flex-wrap: nowrap; align-items: stretch; height: 100vh; }

@media print, screen and (min-width: 40em) { .grid-y.medium-grid-frame { width: auto; overflow: hidden; position: relative; flex-wrap: nowrap; align-items: stretch; height: 100vh; } }

@media print, screen and (min-width: 64em) { .grid-y.large-grid-frame { width: auto; overflow: hidden; position: relative; flex-wrap: nowrap; align-items: stretch; height: 100vh; } }

.cell .grid-y.grid-frame { height: 100%; }

@media print, screen and (min-width: 40em) { .cell .grid-y.medium-grid-frame { height: 100%; } }

@media print, screen and (min-width: 64em) { .cell .grid-y.large-grid-frame { height: 100%; } }

.grid-margin-y { margin-top: -0.625rem; margin-bottom: -0.625rem; }

@media print, screen and (min-width: 40em) { .grid-margin-y { margin-top: -0.9375rem; margin-bottom: -0.9375rem; } }

.grid-margin-y > .cell { height: calc(100% - 1.25rem); margin-top: 0.625rem; margin-bottom: 0.625rem; }

@media print, screen and (min-width: 40em) { .grid-margin-y > .cell { height: calc(100% - 1.875rem); margin-top: 0.9375rem; margin-bottom: 0.9375rem; } }

.grid-margin-y > .auto { height: auto; }

.grid-margin-y > .shrink { height: auto; }

.grid-margin-y > .small-1 { height: calc(8.3333333333% - 1.25rem); }

.grid-margin-y > .small-2 { height: calc(16.6666666667% - 1.25rem); }

.grid-margin-y > .small-3 { height: calc(25% - 1.25rem); }

.grid-margin-y > .small-4 { height: calc(33.3333333333% - 1.25rem); }

.grid-margin-y > .small-5 { height: calc(41.6666666667% - 1.25rem); }

.grid-margin-y > .small-6 { height: calc(50% - 1.25rem); }

.grid-margin-y > .small-7 { height: calc(58.3333333333% - 1.25rem); }

.grid-margin-y > .small-8 { height: calc(66.6666666667% - 1.25rem); }

.grid-margin-y > .small-9 { height: calc(75% - 1.25rem); }

.grid-margin-y > .small-10 { height: calc(83.3333333333% - 1.25rem); }

.grid-margin-y > .small-11 { height: calc(91.6666666667% - 1.25rem); }

.grid-margin-y > .small-12 { height: calc(100% - 1.25rem); }

@media print, screen and (min-width: 40em) { .grid-margin-y > .auto { height: auto; }
  .grid-margin-y > .shrink { height: auto; }
  .grid-margin-y > .small-1 { height: calc(8.3333333333% - 1.875rem); }
  .grid-margin-y > .small-2 { height: calc(16.6666666667% - 1.875rem); }
  .grid-margin-y > .small-3 { height: calc(25% - 1.875rem); }
  .grid-margin-y > .small-4 { height: calc(33.3333333333% - 1.875rem); }
  .grid-margin-y > .small-5 { height: calc(41.6666666667% - 1.875rem); }
  .grid-margin-y > .small-6 { height: calc(50% - 1.875rem); }
  .grid-margin-y > .small-7 { height: calc(58.3333333333% - 1.875rem); }
  .grid-margin-y > .small-8 { height: calc(66.6666666667% - 1.875rem); }
  .grid-margin-y > .small-9 { height: calc(75% - 1.875rem); }
  .grid-margin-y > .small-10 { height: calc(83.3333333333% - 1.875rem); }
  .grid-margin-y > .small-11 { height: calc(91.6666666667% - 1.875rem); }
  .grid-margin-y > .small-12 { height: calc(100% - 1.875rem); }
  .grid-margin-y > .medium-auto { height: auto; }
  .grid-margin-y > .medium-shrink { height: auto; }
  .grid-margin-y > .medium-1 { height: calc(8.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-2 { height: calc(16.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-3 { height: calc(25% - 1.875rem); }
  .grid-margin-y > .medium-4 { height: calc(33.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-5 { height: calc(41.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-6 { height: calc(50% - 1.875rem); }
  .grid-margin-y > .medium-7 { height: calc(58.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-8 { height: calc(66.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-9 { height: calc(75% - 1.875rem); }
  .grid-margin-y > .medium-10 { height: calc(83.3333333333% - 1.875rem); }
  .grid-margin-y > .medium-11 { height: calc(91.6666666667% - 1.875rem); }
  .grid-margin-y > .medium-12 { height: calc(100% - 1.875rem); } }

@media print, screen and (min-width: 64em) { .grid-margin-y > .large-auto { height: auto; }
  .grid-margin-y > .large-shrink { height: auto; }
  .grid-margin-y > .large-1 { height: calc(8.3333333333% - 1.875rem); }
  .grid-margin-y > .large-2 { height: calc(16.6666666667% - 1.875rem); }
  .grid-margin-y > .large-3 { height: calc(25% - 1.875rem); }
  .grid-margin-y > .large-4 { height: calc(33.3333333333% - 1.875rem); }
  .grid-margin-y > .large-5 { height: calc(41.6666666667% - 1.875rem); }
  .grid-margin-y > .large-6 { height: calc(50% - 1.875rem); }
  .grid-margin-y > .large-7 { height: calc(58.3333333333% - 1.875rem); }
  .grid-margin-y > .large-8 { height: calc(66.6666666667% - 1.875rem); }
  .grid-margin-y > .large-9 { height: calc(75% - 1.875rem); }
  .grid-margin-y > .large-10 { height: calc(83.3333333333% - 1.875rem); }
  .grid-margin-y > .large-11 { height: calc(91.6666666667% - 1.875rem); }
  .grid-margin-y > .large-12 { height: calc(100% - 1.875rem); } }

.grid-frame.grid-margin-y { height: calc(100vh + 1.25rem); }

@media print, screen and (min-width: 40em) { .grid-frame.grid-margin-y { height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 64em) { .grid-frame.grid-margin-y { height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 40em) { .grid-margin-y.medium-grid-frame { height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 64em) { .grid-margin-y.large-grid-frame { height: calc(100vh + 1.875rem); } }

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td { margin: 0; padding: 0; }

p { margin-bottom: 1rem; font-size: inherit; line-height: 1.6; text-rendering: optimizeLegibility; }

em, i { font-style: italic; line-height: inherit; }

strong, b { font-weight: bold; line-height: inherit; }

small { font-size: 80%; line-height: inherit; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; font-style: normal; font-weight: normal; color: inherit; text-rendering: optimizeLegibility; }

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small { line-height: 0; color: #cacaca; }

h1, .h1 { font-size: 1.5rem; line-height: 1.4; margin-top: 0; margin-bottom: 0.5rem; }

h2, .h2 { font-size: 1.25rem; line-height: 1.4; margin-top: 0; margin-bottom: 0.5rem; }

h3, .h3 { font-size: 1.1875rem; line-height: 1.4; margin-top: 0; margin-bottom: 0.5rem; }

h4, .h4 { font-size: 1.125rem; line-height: 1.4; margin-top: 0; margin-bottom: 0.5rem; }

h5, .h5 { font-size: 1.0625rem; line-height: 1.4; margin-top: 0; margin-bottom: 0.5rem; }

h6, .h6 { font-size: 1rem; line-height: 1.4; margin-top: 0; margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) { h1, .h1 { font-size: 3rem; }
  h2, .h2 { font-size: 2.5rem; }
  h3, .h3 { font-size: 1.9375rem; }
  h4, .h4 { font-size: 1.5625rem; }
  h5, .h5 { font-size: 1.25rem; }
  h6, .h6 { font-size: 1rem; } }

a { line-height: inherit; color: #1779ba; text-decoration: none; cursor: pointer; }

a:hover, a:focus { color: #1468a0; }

a img { border: 0; }

hr { clear: both; max-width: 75rem; height: 0; margin: 1.25rem auto; border-top: 0; border-right: 0; border-bottom: 1px solid #cacaca; border-left: 0; }

ul, ol, dl { margin-bottom: 1rem; list-style-position: outside; line-height: 1.6; }

li { font-size: inherit; }

ul { margin-left: 1.25rem; list-style-type: disc; }

ol { margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol { margin-left: 1.25rem; margin-bottom: 0; }

dl { margin-bottom: 1rem; }

dl dt { margin-bottom: 0.3rem; font-weight: bold; }

blockquote { margin: 0 0 1rem; padding: 0.5625rem 1.25rem 0 1.1875rem; border-left: 1px solid #cacaca; }

blockquote, blockquote p { line-height: 1.6; color: #8a8a8a; }

cite { display: block; font-size: 0.8125rem; color: #8a8a8a; }

cite:before { content: "— "; }

abbr, abbr[title] { border-bottom: 1px dotted #0a0a0a; cursor: help; text-decoration: none; }

figure { margin: 0; }

code { padding: 0.125rem 0.3125rem 0.0625rem; border: 1px solid #cacaca; background-color: #e6e6e6; font-family: Consolas, "Liberation Mono", Courier, monospace; font-weight: normal; color: #0a0a0a; }

kbd { margin: 0; padding: 0.125rem 0.25rem 0; background-color: #e6e6e6; font-family: Consolas, "Liberation Mono", Courier, monospace; color: #0a0a0a; }

.subheader { margin-top: 0.2rem; margin-bottom: 0.5rem; font-weight: normal; line-height: 1.4; color: #8a8a8a; }

.lead { font-size: 125%; line-height: 1.6; }

.stat { font-size: 2.5rem; line-height: 1; }

p + .stat { margin-top: -1rem; }

ul.no-bullet, ol.no-bullet { margin-left: 0; list-style: none; }

.text-left { text-align: left; }

.text-right { text-align: right; }

.text-center { text-align: center; }

.text-justify { text-align: justify; }

@media print, screen and (min-width: 40em) { .medium-text-left { text-align: left; }
  .medium-text-right { text-align: right; }
  .medium-text-center { text-align: center; }
  .medium-text-justify { text-align: justify; } }

@media print, screen and (min-width: 64em) { .large-text-left { text-align: left; }
  .large-text-right { text-align: right; }
  .large-text-center { text-align: center; }
  .large-text-justify { text-align: justify; } }

.show-for-print { display: none !important; }

@media print { * { background: transparent !important; box-shadow: none !important; color: black !important; text-shadow: none !important; }
  .show-for-print { display: block !important; }
  .hide-for-print { display: none !important; }
  table.show-for-print { display: table !important; }
  thead.show-for-print { display: table-header-group !important; }
  tbody.show-for-print { display: table-row-group !important; }
  tr.show-for-print { display: table-row !important; }
  td.show-for-print { display: table-cell !important; }
  th.show-for-print { display: table-cell !important; }
  a, a:visited { text-decoration: underline; }
  a[href]:after { content: " (" attr(href) ")"; }
  .ir a:after, a[href^='javascript:']:after, a[href^='#']:after { content: ''; }
  abbr[title]:after { content: " (" attr(title) ")"; }
  pre, blockquote { border: 1px solid #8a8a8a; page-break-inside: avoid; }
  thead { display: table-header-group; }
  tr, img { page-break-inside: avoid; }
  img { max-width: 100% !important; }
  @page { margin: 0.5cm; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
  .print-break-inside { page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea { display: block; box-sizing: border-box; width: 100%; height: 2.4375rem; margin: 0 0 1rem; padding: 0.5rem; border: 1px solid #cacaca; border-radius: 0; background-color: #fefefe; box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1); font-family: inherit; font-size: 1rem; font-weight: normal; line-height: 1.5; color: #0a0a0a; transition: box-shadow 0.5s, border-color 0.25s ease-in-out; appearance: none; }

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus, textarea:focus { outline: none; border: 1px solid #8a8a8a; background-color: #fefefe; box-shadow: 0 0 5px #cacaca; transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea { max-width: 100%; }

textarea[rows] { height: auto; }

input::placeholder, textarea::placeholder { color: #cacaca; }

input:disabled, input[readonly], textarea:disabled, textarea[readonly] { background-color: #e6e6e6; cursor: not-allowed; }

[type='submit'], [type='button'] { appearance: none; border-radius: 0; }

input[type='search'] { box-sizing: border-box; }

[type='file'], [type='checkbox'], [type='radio'] { margin: 0 0 1rem; }

[type='checkbox'] + label, [type='radio'] + label { display: inline-block; vertical-align: baseline; margin-left: 0.5rem; margin-right: 1rem; margin-bottom: 0; }

[type='checkbox'] + label[for], [type='radio'] + label[for] { cursor: pointer; }

label > [type='checkbox'], label > [type='radio'] { margin-right: 0.5rem; }

[type='file'] { width: 100%; }

label { display: block; margin: 0; font-size: 0.875rem; font-weight: normal; line-height: 1.8; color: #0a0a0a; }

label.middle { margin: 0 0 1rem; padding: 0.5625rem 0; }

.help-text { margin-top: -0.5rem; font-size: 0.8125rem; font-style: italic; color: #0a0a0a; }

.input-group { display: table; width: 100%; margin-bottom: 1rem; }

.input-group > :first-child { border-radius: 0 0 0 0; }

.input-group > :last-child > * { border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label { margin: 0; white-space: nowrap; display: table-cell; vertical-align: middle; }

.input-group-label { padding: 0 1rem; border: 1px solid #cacaca; background: #e6e6e6; color: #0a0a0a; text-align: center; white-space: nowrap; width: 1%; height: 100%; }

.input-group-label:first-child { border-right: 0; }

.input-group-label:last-child { border-left: 0; }

.input-group-field { border-radius: 0; height: 2.5rem; }

.input-group-button { padding-top: 0; padding-bottom: 0; text-align: center; width: 1%; height: 100%; }

.input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label { height: 2.5rem; padding-top: 0; padding-bottom: 0; font-size: 1rem; }

.input-group .input-group-button { display: table-cell; }

fieldset { margin: 0; padding: 0; border: 0; }

legend { max-width: 100%; margin-bottom: 0.5rem; }

.fieldset { margin: 1.125rem 0; padding: 1.25rem; border: 1px solid #cacaca; }

.fieldset legend { margin: 0; margin-left: -0.1875rem; padding: 0 0.1875rem; }

select { height: 2.4375rem; margin: 0 0 1rem; padding: 0.5rem; appearance: none; border: 1px solid #cacaca; border-radius: 0; background-color: #fefefe; font-family: inherit; font-size: 1rem; font-weight: normal; line-height: 1.5; color: #0a0a0a; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>"); background-origin: content-box; background-position: right -1rem center; background-repeat: no-repeat; background-size: 9px 6px; padding-right: 1.5rem; transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

@media screen and (min-width: 0\0) { select { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }

select:focus { outline: none; border: 1px solid #8a8a8a; background-color: #fefefe; box-shadow: 0 0 5px #cacaca; transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

select:disabled { background-color: #e6e6e6; cursor: not-allowed; }

select::-ms-expand { display: none; }

select[multiple] { height: auto; background-image: none; }

.is-invalid-input:not(:focus) { border-color: #cc4b37; background-color: #f9ecea; }

.is-invalid-input:not(:focus)::placeholder { color: #cc4b37; }

.is-invalid-label { color: #cc4b37; }

.form-error { display: none; margin-top: -0.5rem; margin-bottom: 1rem; font-size: 0.75rem; font-weight: bold; color: #cc4b37; }

.form-error.is-visible { display: block; }

.button { display: inline-block; vertical-align: middle; margin: 0 0 1rem 0; font-family: inherit; padding: 0.85em 1em; -webkit-appearance: none; border: 1px solid transparent; border-radius: 0; transition: background-color 0.25s ease-out, color 0.25s ease-out; font-size: 0.9rem; line-height: 1; text-align: center; cursor: pointer; background-color: #1779ba; color: #fefefe; }

[data-whatinput='mouse'] .button { outline: 0; }

.button:hover, .button:focus { background-color: #14679e; color: #fefefe; }

.button.tiny { font-size: 0.6rem; }

.button.small { font-size: 0.75rem; }

.button.large { font-size: 1.25rem; }

.button.expanded { display: block; width: 100%; margin-right: 0; margin-left: 0; }

.button.primary { background-color: #1779ba; color: #fefefe; }

.button.primary:hover, .button.primary:focus { background-color: #126195; color: #fefefe; }

.button.secondary { background-color: #767676; color: #fefefe; }

.button.secondary:hover, .button.secondary:focus { background-color: #5e5e5e; color: #fefefe; }

.button.success { background-color: #3adb76; color: #0a0a0a; }

.button.success:hover, .button.success:focus { background-color: #22bb5b; color: #0a0a0a; }

.button.warning { background-color: #ffae00; color: #0a0a0a; }

.button.warning:hover, .button.warning:focus { background-color: #cc8b00; color: #0a0a0a; }

.button.alert { background-color: #cc4b37; color: #fefefe; }

.button.alert:hover, .button.alert:focus { background-color: #a53b2a; color: #fefefe; }

.button.disabled, .button[disabled] { opacity: 0.25; cursor: not-allowed; }

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus { background-color: #1779ba; color: #fefefe; }

.button.disabled.primary, .button[disabled].primary { opacity: 0.25; cursor: not-allowed; }

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus { background-color: #1779ba; color: #fefefe; }

.button.disabled.secondary, .button[disabled].secondary { opacity: 0.25; cursor: not-allowed; }

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus { background-color: #767676; color: #fefefe; }

.button.disabled.success, .button[disabled].success { opacity: 0.25; cursor: not-allowed; }

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus { background-color: #3adb76; color: #0a0a0a; }

.button.disabled.warning, .button[disabled].warning { opacity: 0.25; cursor: not-allowed; }

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus { background-color: #ffae00; color: #0a0a0a; }

.button.disabled.alert, .button[disabled].alert { opacity: 0.25; cursor: not-allowed; }

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus { background-color: #cc4b37; color: #fefefe; }

.button.hollow { border: 1px solid #1779ba; color: #1779ba; }

.button.hollow, .button.hollow:hover, .button.hollow:focus { background-color: transparent; }

.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus { background-color: transparent; }

.button.hollow:hover, .button.hollow:focus { border-color: #0c3d5d; color: #0c3d5d; }

.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] { border: 1px solid #1779ba; color: #1779ba; }

.button.hollow.primary { border: 1px solid #1779ba; color: #1779ba; }

.button.hollow.primary:hover, .button.hollow.primary:focus { border-color: #0c3d5d; color: #0c3d5d; }

.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] { border: 1px solid #1779ba; color: #1779ba; }

.button.hollow.secondary { border: 1px solid #767676; color: #767676; }

.button.hollow.secondary:hover, .button.hollow.secondary:focus { border-color: #3b3b3b; color: #3b3b3b; }

.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] { border: 1px solid #767676; color: #767676; }

.button.hollow.success { border: 1px solid #3adb76; color: #3adb76; }

.button.hollow.success:hover, .button.hollow.success:focus { border-color: #157539; color: #157539; }

.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] { border: 1px solid #3adb76; color: #3adb76; }

.button.hollow.warning { border: 1px solid #ffae00; color: #ffae00; }

.button.hollow.warning:hover, .button.hollow.warning:focus { border-color: #805700; color: #805700; }

.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] { border: 1px solid #ffae00; color: #ffae00; }

.button.hollow.alert { border: 1px solid #cc4b37; color: #cc4b37; }

.button.hollow.alert:hover, .button.hollow.alert:focus { border-color: #67251a; color: #67251a; }

.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] { border: 1px solid #cc4b37; color: #cc4b37; }

.button.clear { border: 1px solid #1779ba; color: #1779ba; }

.button.clear, .button.clear:hover, .button.clear:focus { background-color: transparent; }

.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus { background-color: transparent; }

.button.clear:hover, .button.clear:focus { border-color: #0c3d5d; color: #0c3d5d; }

.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] { border: 1px solid #1779ba; color: #1779ba; }

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] { border-color: transparent; }

.button.clear.primary { border: 1px solid #1779ba; color: #1779ba; }

.button.clear.primary:hover, .button.clear.primary:focus { border-color: #0c3d5d; color: #0c3d5d; }

.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] { border: 1px solid #1779ba; color: #1779ba; }

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] { border-color: transparent; }

.button.clear.secondary { border: 1px solid #767676; color: #767676; }

.button.clear.secondary:hover, .button.clear.secondary:focus { border-color: #3b3b3b; color: #3b3b3b; }

.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] { border: 1px solid #767676; color: #767676; }

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] { border-color: transparent; }

.button.clear.success { border: 1px solid #3adb76; color: #3adb76; }

.button.clear.success:hover, .button.clear.success:focus { border-color: #157539; color: #157539; }

.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] { border: 1px solid #3adb76; color: #3adb76; }

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] { border-color: transparent; }

.button.clear.warning { border: 1px solid #ffae00; color: #ffae00; }

.button.clear.warning:hover, .button.clear.warning:focus { border-color: #805700; color: #805700; }

.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] { border: 1px solid #ffae00; color: #ffae00; }

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] { border-color: transparent; }

.button.clear.alert { border: 1px solid #cc4b37; color: #cc4b37; }

.button.clear.alert:hover, .button.clear.alert:focus { border-color: #67251a; color: #67251a; }

.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] { border: 1px solid #cc4b37; color: #cc4b37; }

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] { border-color: transparent; }

.button.dropdown::after { display: block; width: 0; height: 0; border: inset 0.4em; content: ''; border-bottom-width: 0; border-top-style: solid; border-color: #fefefe transparent transparent; position: relative; top: 0.4em; display: inline-block; float: right; margin-left: 1em; }

.button.dropdown.hollow::after { border-top-color: #1779ba; }

.button.dropdown.hollow.primary::after { border-top-color: #1779ba; }

.button.dropdown.hollow.secondary::after { border-top-color: #767676; }

.button.dropdown.hollow.success::after { border-top-color: #3adb76; }

.button.dropdown.hollow.warning::after { border-top-color: #ffae00; }

.button.dropdown.hollow.alert::after { border-top-color: #cc4b37; }

.button.arrow-only::after { top: -0.1em; float: none; margin-left: 0; }

a.button:hover, a.button:focus { text-decoration: none; }

.hide { display: none !important; }

.invisible { visibility: hidden; }

@media screen and (max-width: 39.9375em) { .hide-for-small-only { display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) { .show-for-small-only { display: none !important; } }

@media print, screen and (min-width: 40em) { .hide-for-medium { display: none !important; } }

@media screen and (max-width: 39.9375em) { .show-for-medium { display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) { .hide-for-medium-only { display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) { .show-for-medium-only { display: none !important; } }

@media print, screen and (min-width: 64em) { .hide-for-large { display: none !important; } }

@media screen and (max-width: 63.9375em) { .show-for-large { display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) { .hide-for-large-only { display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) { .show-for-large-only { display: none !important; } }

.show-for-sr, .show-on-focus { position: absolute !important; width: 1px; height: 1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; clip-path: inset(50%); border: 0; }

.show-on-focus:active, .show-on-focus:focus { position: static !important; width: auto; height: auto; overflow: visible; clip: auto; white-space: normal; clip-path: none; }

.show-for-landscape, .hide-for-portrait { display: block !important; }

@media screen and (orientation: landscape) { .show-for-landscape, .hide-for-portrait { display: block !important; } }

@media screen and (orientation: portrait) { .show-for-landscape, .hide-for-portrait { display: none !important; } }

.hide-for-landscape, .show-for-portrait { display: none !important; }

@media screen and (orientation: landscape) { .hide-for-landscape, .show-for-portrait { display: none !important; } }

@media screen and (orientation: portrait) { .hide-for-landscape, .show-for-portrait { display: block !important; } }

.float-left { float: left !important; }

.float-right { float: right !important; }

.float-center { display: block; margin-right: auto; margin-left: auto; }

.clearfix::before, .clearfix::after { display: table; content: ' '; }

.clearfix::after { clear: both; }

table { border-collapse: collapse; width: 100%; margin-bottom: 1rem; border-radius: 0; }

thead, tbody, tfoot { border: 1px solid #f1f1f1; background-color: #fefefe; }

caption { padding: 0.5rem 0.625rem 0.625rem; font-weight: bold; }

thead { background: #f8f8f8; color: #0a0a0a; }

tfoot { background: #f1f1f1; color: #0a0a0a; }

thead tr, tfoot tr { background: transparent; }

thead th, thead td, tfoot th, tfoot td { padding: 0.5rem 0.625rem 0.625rem; font-weight: bold; text-align: left; }

tbody th, tbody td { padding: 0.5rem 0.625rem 0.625rem; }

tbody tr:nth-child(even) { border-bottom: 0; background-color: #f1f1f1; }

table.unstriped tbody { background-color: #fefefe; }

table.unstriped tbody tr { border-bottom: 0; border-bottom: 1px solid #f1f1f1; background-color: #fefefe; }

@media screen and (max-width: 63.9375em) { table.stack thead { display: none; }
  table.stack tfoot { display: none; }
  table.stack tr, table.stack th, table.stack td { display: block; }
  table.stack td { border-top: 0; } }

table.scroll { display: block; width: 100%; overflow-x: auto; }

table.hover thead tr:hover { background-color: #f3f3f3; }

table.hover tfoot tr:hover { background-color: #ececec; }

table.hover tbody tr:hover { background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover { background-color: #ececec; }

.table-scroll { overflow-x: auto; }

.table-scroll table { width: auto; }

.responsive-embed, .flex-video { position: relative; height: 0; margin-bottom: 1rem; padding-bottom: 75%; overflow: hidden; }

.responsive-embed iframe, .responsive-embed object, .responsive-embed embed, .responsive-embed video, .flex-video iframe, .flex-video object, .flex-video embed, .flex-video video { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.responsive-embed.widescreen, .flex-video.widescreen { padding-bottom: 56.25%; }

.menu { padding: 0; margin: 0; list-style: none; position: relative; }

[data-whatinput='mouse'] .menu li { outline: 0; }

.menu a, .menu .button { line-height: 1; text-decoration: none; display: block; padding: 0.7rem 1rem; }

.menu input, .menu select, .menu a, .menu button { margin-bottom: 0; }

.menu input { display: inline-block; }

.menu li, .menu.horizontal li { display: inline-block; }

.menu.vertical li { display: block; }

.menu.expanded { display: table; width: 100%; }

.menu.expanded > li { display: table-cell; vertical-align: middle; }

.menu.simple li + li { margin-left: 1rem; }

.menu.simple a { padding: 0; }

@media print, screen and (min-width: 40em) { .menu.medium-horizontal li { display: inline-block; }
  .menu.medium-vertical li { display: block; }
  .menu.medium-expanded { display: table; width: 100%; }
  .menu.medium-expanded > li { display: table-cell; vertical-align: middle; }
  .menu.medium-simple { display: table; width: 100%; }
  .menu.medium-simple > li { display: table-cell; vertical-align: middle; } }

@media print, screen and (min-width: 64em) { .menu.large-horizontal li { display: inline-block; }
  .menu.large-vertical li { display: block; }
  .menu.large-expanded { display: table; width: 100%; }
  .menu.large-expanded > li { display: table-cell; vertical-align: middle; }
  .menu.large-simple { display: table; width: 100%; }
  .menu.large-simple > li { display: table-cell; vertical-align: middle; } }

.menu.nested { margin-right: 0; margin-left: 1rem; }

.menu.icons img, .menu.icons i, .menu.icons svg { vertical-align: middle; }

.menu.icons img + span, .menu.icons i + span, .menu.icons svg + span { vertical-align: middle; }

.menu.icon-top img, .menu.icon-top i, .menu.icon-top svg, .menu.icon-right img, .menu.icon-right i, .menu.icon-right svg, .menu.icon-bottom img, .menu.icon-bottom i, .menu.icon-bottom svg, .menu.icon-left img, .menu.icon-left i, .menu.icon-left svg { vertical-align: middle; }

.menu.icon-top img + span, .menu.icon-top i + span, .menu.icon-top svg + span, .menu.icon-right img + span, .menu.icon-right i + span, .menu.icon-right svg + span, .menu.icon-bottom img + span, .menu.icon-bottom i + span, .menu.icon-bottom svg + span, .menu.icon-left img + span, .menu.icon-left i + span, .menu.icon-left svg + span { vertical-align: middle; }

.menu.icon-left li a img, .menu.icon-left li a i, .menu.icon-left li a svg { margin-right: 0.25rem; display: inline-block; }

.menu.icon-right li a img, .menu.icon-right li a i, .menu.icon-right li a svg { margin-left: 0.25rem; display: inline-block; }

.menu.icon-top li a { text-align: center; }

.menu.icon-top li a img, .menu.icon-top li a i, .menu.icon-top li a svg { display: block; margin: 0 auto 0.25rem; }

.menu.icon-bottom li a { text-align: center; }

.menu.icon-bottom li a img, .menu.icon-bottom li a i, .menu.icon-bottom li a svg { display: block; margin: 0.25rem auto 0; }

.menu .is-active > a { background: #1779ba; color: #fefefe; }

.menu .active > a { background: #1779ba; color: #fefefe; }

.menu.align-left { text-align: left; }

.menu.align-right { text-align: right; }

.menu.align-right .submenu li { text-align: left; }

.menu.align-right.vertical .submenu li { text-align: right; }

.menu.align-right .nested { margin-right: 1rem; margin-left: 0; }

.menu.align-center { text-align: center; }

.menu.align-center .submenu li { text-align: left; }

.menu .menu-text { padding: 0.7rem 1rem; font-weight: bold; line-height: 1; color: inherit; }

.menu-centered > .menu { text-align: center; }

.menu-centered > .menu .submenu li { text-align: left; }

.no-js [data-responsive-menu] ul { display: none; }

.menu-icon { position: relative; display: inline-block; vertical-align: middle; width: 20px; height: 16px; cursor: pointer; }

.menu-icon::after { position: absolute; top: 0; left: 0; display: block; width: 100%; height: 2px; background: #fefefe; box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe; content: ''; }

.menu-icon:hover::after { background: #cacaca; box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark { position: relative; display: inline-block; vertical-align: middle; width: 20px; height: 16px; cursor: pointer; }

.menu-icon.dark::after { position: absolute; top: 0; left: 0; display: block; width: 100%; height: 2px; background: #0a0a0a; box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a; content: ''; }

.menu-icon.dark:hover::after { background: #8a8a8a; box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.title-bar { padding: 0.5rem; background: #0a0a0a; color: #fefefe; }

.title-bar::before, .title-bar::after { display: table; content: ' '; }

.title-bar::after { clear: both; }

.title-bar .menu-icon { margin-left: 0.25rem; margin-right: 0.25rem; }

.title-bar-left { float: left; }

.title-bar-right { float: right; text-align: right; }

.title-bar-title { display: inline-block; vertical-align: middle; font-weight: bold; }

.top-bar { padding: 0.5rem; }

.top-bar::before, .top-bar::after { display: table; content: ' '; }

.top-bar::after { clear: both; }

.top-bar, .top-bar ul { background-color: #e6e6e6; }

.top-bar input { max-width: 200px; margin-right: 1rem; }

.top-bar .input-group-field { width: 100%; margin-right: 0; }

.top-bar input.button { width: auto; }

.top-bar .top-bar-left, .top-bar .top-bar-right { width: 100%; }

@media print, screen and (min-width: 40em) { .top-bar .top-bar-left, .top-bar .top-bar-right { width: auto; } }

@media screen and (max-width: 63.9375em) { .top-bar.stacked-for-medium .top-bar-left, .top-bar.stacked-for-medium .top-bar-right { width: 100%; } }

@media screen and (max-width: 74.9375em) { .top-bar.stacked-for-large .top-bar-left, .top-bar.stacked-for-large .top-bar-right { width: 100%; } }

.top-bar-title { display: inline-block; float: left; padding: 0.5rem 1rem 0.5rem 0; }

.top-bar-title .menu-icon { bottom: 2px; }

.top-bar-left { float: left; }

.top-bar-right { float: right; }

.callout { position: relative; margin: 0 0 1rem 0; padding: 1rem; border: 1px solid rgba(10, 10, 10, 0.25); border-radius: 0; background-color: white; color: #0a0a0a; }

.callout > :first-child { margin-top: 0; }

.callout > :last-child { margin-bottom: 0; }

.callout.primary { background-color: #d7ecfa; color: #0a0a0a; }

.callout.secondary { background-color: #eaeaea; color: #0a0a0a; }

.callout.success { background-color: #e1faea; color: #0a0a0a; }

.callout.warning { background-color: #fff3d9; color: #0a0a0a; }

.callout.alert { background-color: #f7e4e1; color: #0a0a0a; }

.callout.small { padding-top: 0.5rem; padding-right: 0.5rem; padding-bottom: 0.5rem; padding-left: 0.5rem; }

.callout.large { padding-top: 3rem; padding-right: 3rem; padding-bottom: 3rem; padding-left: 3rem; }

/* Font Styles */
/* http://www.sitepoint.com/sass-mixin-placeholder/ Mixins allow you to define styles that can be re-used throughout the stylesheet without needing to resort to non-semantic classes like .float-left. Mixins can also contain full CSS rules, and anything else allowed elsewhere in a Sass document. They can even take arguments which allows you to produce a wide variety of styles with very few mixins. */
/* http://thesassway.com/advanced/pure-sass-functions */
/** http://thesassway.com/intermediate/understanding-placeholder-selectors http://www.sitepoint.com/sass-mixin-placeholder/ Removed %hidden-text, use foundations `@include element-invisible;` or `.show-for-sr` */
.comment-list, .comment { list-style-type: none; margin-left: 0; }

.comment-list .comment { background: #f4f4f4; margin: 15px 0; padding: 20px 0 20px 15px; }

.comment-list .children { background: #fefefe; margin: 15px 0 0 15px; padding: 5px 0 0 15px; }

.comment-list .comment-body { padding: 15px 20px; }

.comment-list .comment-content p:last-child { margin: 0; }

/*
 *
 * No easy was to get at Wordpresses default submit buttons on comments
 * Addeding the submit class to the button stylings for foundation
 *
*/
.submit { display: inline-block; vertical-align: middle; margin: 0 0 1rem 0; font-family: inherit; padding: 0.85em 1em; -webkit-appearance: none; border: 1px solid transparent; border-radius: 0; transition: background-color 0.25s ease-out, color 0.25s ease-out; font-size: 0.9rem; line-height: 1; text-align: center; cursor: pointer; background-color: #1779ba; color: #fefefe; }

[data-whatinput='mouse'] .submit { outline: 0; }

.submit:hover, .submit:focus { background-color: #14679e; color: #fefefe; }

.main-menu { background-color: #e6e6e6; margin: 0; }

.main-menu li { display: inline-block; list-style-type: none; }

.main-menu a { display: block; padding: 10px 20px; }

.main-menu a:hover { background-color: #cacaca; }

.post-navigation { background-color: #e6e6e6; margin-top: 20px; padding: 10px 20px; }

.post-navigation::before, .post-navigation::after { display: table; content: ' '; }

.post-navigation::after { clear: both; }

.post-navigation .nav-previous { float: right; }

.post-navigation .nav-previous:after { content: '\2192'; margin-left: 5px; }

.post-navigation .nav-next { float: left; }

.post-navigation .nav-next:before { content: '\2190'; margin-right: 5px; }

body { word-wrap: break-word; }

/* Click button for displaying page template parts */
.regions { background: rgba(10, 10, 10, 0.1); bottom: 10px; color: #fefefe; padding: 10px 20px; position: fixed; right: 10px; z-index: 2000000; }

.regions:hover, .regions:focus { color: #fefefe; }

.hidden { display: none; }

/* Foundation figure was coming out of the layout container */
figure { background: #f4f4f4; margin: 0; max-width: 100% !important; padding: 1em 20px; width: auto !important; }

figure img { display: block; margin: 0 auto; }

figure figcaption { font-size: 12px; margin: 13px 0 0; text-align: center; }

/* Wordpress styles */
.alignright { float: right; margin: 0 0 0 25px; }

.alignleft { float: left; margin: 0 25px 0 0; }

.aligncenter { display: block; margin-left: auto; margin-right: auto; }

pre { background-color: #e6e6e6; border: 1px solid #cacaca; color: #0a0a0a; font-family: Consolas, "Liberation Mono", Courier, monospace; font-weight: normal; padding: 0.125rem 0.3125rem 0.0625rem; white-space: pre-wrap; }

[type='text'], [type='password'] { height: 2.48rem; }

.edit-link { margin-bottom: 0; }

.edit-link .button { margin-bottom: 0; margin-top: 25px; }

.screen-reader-text { position: absolute !important; width: 1px; height: 1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; clip-path: inset(50%); border: 0; }

/* Avoid using this file */
