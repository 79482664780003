.post-navigation {
	@include clearfix;

	background-color: $light-gray;
	margin-top: 20px;
	padding: 10px 20px;

	.nav-previous {
		float: right;

		&:after {
			content: '\2192';
			margin-left: 5px;
		}
	}

	.nav-next {
		float: left;

		&:before {
			content: '\2190';
			margin-right: 5px;
		}
	}
}
