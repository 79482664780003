.comment-list,
.comment {
	list-style-type: none;
	margin-left: 0;
}

.comment-list {
	.comment {
		background: $gray244;
		margin: 15px 0;
		padding: 20px 0 20px 15px;
	}

	.children {
		background: $white;
		margin: 15px 0 0 15px;
		padding: 5px 0 0 15px;
	}

	.comment-body {
		padding: 15px 20px;
	}

	.comment-content {
		p {
			&:last-child {
				margin: 0;
			}
		}
	}
}

/*
 *
 * No easy was to get at Wordpresses default submit buttons on comments
 * Addeding the submit class to the button stylings for foundation
 *
*/
.submit {
	@include button;
}
